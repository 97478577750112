<template>
    <div class="container">
        <div>
            <!-- Dynamically bind the src attribute to the imported image -->
            <img style="height: 48px;" :src="VETimg" alt="VET Image" />
            <div class="termscondition">
                <h3>លក្ខខណ្ឌក្នុងការអនុវត្តន៍</h3>
                <ol>
                    <li class="main_text">ម៉ោងត្រូវមកដល់ស្ថានីយ៍ដើម្បីត្រៀមចេញដំណើរ</li>
                    <ul>
                        <li> អ្នកដំណើរត្រូវមកដល់ស្ថានីយ៍ចេញដំណើរ និងធ្វើការពិនិត្យផ្ទៀងផ្ទាត់សំបុត្រ
                            និងលេខកៅអីជាមួយបុគ្គលិកយ៉ាងតិច ១៥ នាទីមុន ម៉ោងចេញដំណើរ។</li>
                        <li> អ្នកដំណើរដែលមកដល់ស្ថានីយ៍ចេញដំណើរយឺត(ក្រោយម៉ោងចេញដំណើរ)
                            ឬមិនបង្ហាញខ្លួននៅស្ថានីយ៍ក្នុងកាលបរិច្ឆេទ និង ម៉ោងនៃការធ្វើដំណើរ មិនអាចធ្វើការតវ៉ា
                            ឬទាមទារប្តូរសំបុត្រជាប្រាក់វិញបានទេ។ សំបុត្រនឹងផុតសុពលភាពបន្ទាប់ពីរថយន្តចេញ ដំណើរ។</li>
                    </ul>
                    <li class="main_text">គោលការណ៍អនុវត្តចំពោះសំបុត្រធ្វើដំណើរ</li>
                    <ul>
                        <li>សំបុត្រទិញរួច មិនអាចប្តូរជាប្រាក់វិញបានទេ តែអាចស្នើសុំប្រើជា គូប៉ុង (COUPON)
                            សម្រាប់ការធ្វើដំណើរនៅពេលក្រោយ ដោយត្រូវធ្វើការ ស្នើសុំក្នុងរយៈពេល ១២ ម៉ោង មុនម៉ោងចេញដំណើរ។
                        </li>
                        <li> ការសុំទុកចន្លោះជ្រើសម៉ោងពេលក្រោយ (OPEN) សំបុត្រ ឬប្តូរព័ត៌មានធ្វើដំណើរអាចធ្វើបានក្នុងរយៈពេល
                            ១២ ម៉ោងមុន ពេលចេញដំណើរដោយត្រូវធ្វើការទំនាក់ទំនងមកស្ថានីយ៍ចេញដំណើរដោយផ្ទាល់។ គូប៉ុង (COUPON)
                            និងសំបុត្រទុកចន្លោះ ជ្រើសម៉ោងពេលក្រោយ (OPEN) មានសុពលភាព ៣ ខែ រាប់ចាប់ពីថ្ងៃស្នើសុំ
                            និងអាចប្រើប្រាស់បានចំពោះទិសដៅ និងប្រភេទ ឡានដូចគ្នាតែប៉ុណ្ណោះ(យោងតាមសំបុត្រដើម)។</li>
                    </ul>
                    <li class="main_text">គោលការណ៍អនុវត្តចំពោះទារក កូនក្មេង ស្រ្តីមានផ្ទៃពោះ និងជនពិការ</li>
                    <ul>
                        <li> ក្មេងអាយុក្រោម ៣ ឆ្នាំមិនតម្រូវអោយទិញសំបុត្រនោះទេ
                            ប៉ុន្តែត្រូវអង្គុយលើភ្លៅអាណាព្យាបាលដោយមិនត្រូវបង្កការរំខានដល់អ្នក
                            ដំណើរដែលអង្គុយកៅអីជាប់នោះឡើយ។
                            ក្រុមហ៊ុនមិនទទួលខុសត្រូវលើគ្រោះថ្នាក់ដែលអាចកើតមានឡើងលើក្មេងឡើយ។</li>
                        <li> ក្មេងអាយុចាប់ពី ៣ ឆ្នាំឡើង ឬមានកម្ពស់ចាប់ពី ១ម៉ែត្រ
                            ត្រូវទិញសំបុត្រក្នុងតម្លៃពេញចំពោះឡានកៅអីអង្គុយ។</li>
                        <li> ចំពោះឡានគ្រែគេង កូនក្មេងត្រូវទិញសំបុត្រក្នុងតម្លៃពេញ។ ក្មេងអាយុក្រោម ១៥ឆ្នាំ
                            ត្រូវមានអាណាព្យាបាលរួមដំណើរជាមួយ ដើម្បីមើលការខុសត្រូវ។</li>
                        <li> ស្រ្តីមានផ្ទៃពោះលើសពី ២៧សប្តាហ៍ និងជនពិការ
                            គួរតែទទួលបានការអនុញាតិជាមុនពីក្រុមហ៊ុនមុនពេលទិញសំបុត្រ និងធ្វើ ដំណើរ
                            ដើម្បីធានាបាននូវសុវត្ថិភាព។</li>
                    </ul>
                    <li class="main_text">ដំណើរការឆ្លងដែន</li>
                    <ul>
                        <li> ក្រុមហ៊ុនវិរៈ ប៊ុនថាំ មិនទទួលខុសត្រូវលើរាល់ឯកសារចាំបាច់ (VISA) សម្រាប់នីតិវិធីឆ្លងដែននោះទេ
                            វាជាទំនួលខុសត្រូវរបស់អ្នក ដំណើរ ។</li>
                        <li>សំបុត្រទិញរួចមិនអាចប្តូរជាសាច់ប្រាក់បាននោះទេ ក្នុងករណីអ្នកដំណើរមិនអាចឆ្លងដែនបាន
                            នោះសំបុត្រដែលបានទិញរួចនឹងត្រូវ ផុតសុពលភាពក្រោយពេលរថយន្តចេញដំណើរ ។</li>
                    </ul>
                    <li class="main_text">ការកក់សំបុត្រ និងការជ្រើសរើសកៅអី</li>
                    <ul>
                        <li>អ្នកដំណើរអាចធ្វើការកក់សំបុត្រដោយខ្លួនឯងផ្ទាល់ភ្លាមៗតាមជម្រើសដូចខាងក្រោម៖</li>
                        <li>លេខកក់សំបុត្រ 24/7៖ +85581 911 911</li>
                        <li>កម្មវិធីទូរស័ព្ទ(Mobile app): VET Express</li>
                        <li>វេបសាយ: <a href="http://www.vireakbuntham.com/">http://www.vireakbuntham.com/</a></li>
                        <li>ឬនៅគ្រប់សាខាណាមួយរបស់ក្រុមហ៊ុនវិរៈប៊ុនថាំ ។</li>
                    </ul>
                    <li class="main_text">គោលការណ៍អនុវត្តចំពោះអីវ៉ាន់ដែលអនុញ្ញាតិអោយអ្នកដំណើរយកឡើងរថយន្ត</li>
                    <ul>
                        <li>អ្នកដំណើរអាចយកអីវ៉ាន់តាមខ្លួនក្នុងទំងន់មិនលើសពី ២៥ គីឡូក្រាម។</li>
                        <li>អីវ៉ាន់ដែលមានទំហំធំ ឬលើសកំណត់នឹងត្រូវគិតថ្លៃសេវាបន្ថែម
                            យោងតាមតម្លៃសេវាកម្មបញ្ញើអីវ៉ាន់ជាក់ស្តែង។</li>
                    </ul>
                    <li class="main_text">ប្រភេទអីវ៉ាន់ សម្ភារៈ ឬសត្វដែលហាមយកឡើងរថយន្ត</li>
                    <ul>
                        <li>ខាងក្រោមនេះជាប្រភេទអីវ់ាន់ហាមខាត់យកឡើងរថយន្ត៖</li>
                        <li>ប្រភេទម្ហូបអាហារ ឬផ្លែឈើមានក្លិនខ្លាំង (ឧ.ប្រហុក ផ្អក ផ្លែធុរ៉េន...)</li>
                        <li>ប្រភេទសត្វមានជីវិតគ្រប់ប្រភេទ (ឆ្កែ ឆ្មា មាន់ ទា...)</li>
                        <li>អាវុធមានជាតិផ្ទុះគ្រប់ប្រភេទ</li>
                        <li>គ្រឿងញៀនគ្រប់ប្រភេទ ការជក់បារី បារីអេឡិចត្រូនិក និងការប្រើប្រាស់សារធាតុញៀនក្នុងរថយន្ត</li>
                        <li>ទំនិញខុសច្បាប់</li>
                        <li>វត្ថុងាយឆាបឆេះ គ្រឿងផ្ទុះ ឬសារធាតុបង្កគ្រោះថ្នាក់ផ្សេងៗ</li>
                    </ul>
                    <li class="main_text">ទំនួលខុសត្រូវរបស់អ្នកដំណើរ</li>
                    <ul>
                        <li> អ្នកដំណើរត្រូវទទួលខុសត្រូវលើសុវត្ថិភាព
                            និងការថែទាំទ្រព្យសម្បត្តិផ្ទាល់ខ្លួនដោយខ្លួនឯងផ្ទាល់។</li>
                        <li>ក្រុមហ៊ុនមិនទទួលខុសត្រូវចំពោះការបាក់បែក ឬបាត់បង់ទ្រព្យសម្បត្តិឯកជនរបស់អ្នកដំណើរឡើយ។</li>
                    </ul>
                    <li class="main_text">គោលការណ៍អនុវត្តចំពោះសារជាតិស្រវឹង</li>
                    <ul>
                        <li>ការយកគ្រឿងស្រវឹងចូលរថយន្ត ឬទទួលទានគ្រឿងស្រវឹងលើរថយន្តត្រូវបានហាមឃាត់។</li>
                        <li>ក្រុមហ៊ុនរក្សាសិទ្ធិក្នុងការជម្រាបអោយចុះពីរថយន្តចំពោះអ្នកដំណើរដែលស្រវឹង
                            អ្នកដំណើរដែលគ្មានអនាម័យ ឬអ្នកដំណើរដែល
                            មានឥរិយាបថមិនសមរម្យបង្កការរំខានដល់អ្នកដំណើរផ្សេងទៀតក្នុងរថយន្តដោយមិនអាចទាមទារប្រាក់ថ្លៃសំបុត្រវិញបានឡើយ។
                        </li>
                    </ul>
                </ol>
            </div>

        </div>
        <div>
            <img style="height: 48px;" :src="VETimg" alt="VET Image" />
            <div class="termscondition">
                <h3>Terms and Conditions</h3>
                <ol>
                    <li class="main_text">{{ $t('message.tc1') }}</li>
                    <ul>
                        <li>{{ $t('message.tcd1') }}</li>
                        <li>{{ $t('message.tcd2') }}</li>
                    </ul>
                    <li class="main_text">{{ $t('message.tc2') }}</li>
                    <ul>
                        <li>{{ $t('message.tcd3') }}</li>
                        <li>{{ $t('message.tcd4') }}</li>
                    </ul>
                    <li class="main_text">{{ $t('message.tc3') }}</li>
                    <ul>
                        <li>{{ $t('message.tcd5') }}</li>
                        <li>{{ $t('message.tcd6') }}</li>
                        <li>{{ $t('message.tcd7') }}</li>
                        <li>{{ $t('message.tcd8') }}</li>
                    </ul>
                    <li class="main_text">{{ $t('message.tc4') }}</li>
                    <ul>
                        <li>{{ $t('message.tcd9') }}</li>
                        <li>{{ $t('message.tcd10') }}</li>
                    </ul>
                    <li class="main_text">{{ $t('message.tc5') }}</li>
                    <ul>
                        <li>{{ $t('message.tcd11') }}</li>
                        <li>{{ $t('message.tcd12') }}</li>
                        <li>{{ $t('message.tcd13') }}</li>
                        <li> Website:<a href="https://www.vireakbuntham.com/">{{ $t('message.tcd14') }}</a></li>
                        <li>{{ $t('message.tcd15') }}</li>
                    </ul>
                    <li class="main_text">{{ $t('message.tc6') }}</li>
                    <ul>
                        <li>{{ $t('message.tcd16') }}</li>
                        <li>{{ $t('message.tcd17') }}</li>
                    </ul>
                    <li class="main_text">{{ $t('message.tc7') }}</li>
                    <ul>
                        <li>{{ $t('message.tcd18') }}</li>
                        <li>{{ $t('message.tcd19') }}</li>
                        <li>{{ $t('message.tcd20') }}</li>
                        <li>{{ $t('message.tcd21') }}</li>
                        <li>{{ $t('message.tcd22') }}</li>
                        <li>{{ $t('message.tcd23') }}</li>
                        <li>{{ $t('message.tcd24') }}</li>
                    </ul>
                    <li class="main_text">{{ $t('message.tc8') }}</li>
                    <ul>
                        <li>{{ $t('message.tcd25') }}</li>
                        <li>{{ $t('message.tcd26') }}</li>
                    </ul>
                    <li class="main_text">{{ $t('message.tc9') }}</li>
                    <ul>
                        <li>{{ $t('message.tcd27') }}</li>
                        <li>{{ $t('message.tcd28') }}</li>
                    </ul>
                </ol>
            </div>

        </div>
        <div>
            <img style="height: 48px;" :src="VETimg" alt="VET image">
            <div class="termscondition">
                <h3>条款和条件</h3>
                <ol>
                    <li class="main_text">到达时间</li>
                    <ul>
                        <li>乘客必须在预定出发时间前15分钟到达登车点办理登车手续。</li>
                        <li> 乘客要确保提前或按时到达登车站。如果未能按时到达预定时间或者迟到，恕不退款而已经购买的车票将在巴士出发后失效。</li>
                    </ul>
                    <li class="main_text">票务政策</li>
                    <ul>
                        <li>已购买的车票不可退款，但可以转换为未来行程的购票券。</li>
                        <li>购票券退款可在出发时间前的最多12小时内进行处理。优惠券的有效期为三个月，且仅可用于同一路线和车辆类型的重新预订一次。</li>
                    </ul>
                    <li class="main_text">婴儿、儿童、孕妇和残疾人政策</li>
                    <ul>
                        <li>3岁以下的婴儿无需购票，但须由成人陪同并坐在成人大腿上并不打扰邻座乘客。对于婴儿在旅行期间发生的任何伤害，公司不承担任何责任。</li>
                        <li>3岁及以上或身高超过1米的儿童需全价购买自己的座位。</li>
                        <li>对于卧铺巴士，婴儿需要购买全价票。</li>
                        <li> 27周以上的孕妇或者残疾人士在购票前需提前告知并获得公司的批准，以确保自身安全。本公司对旅行期间发生的任何并发症不承担责任。</li>
                    </ul>
                    <li class="main_text">过境政策</li>
                    <ul>
                        <li>对于跨境行程，Vireak Buntham Express Travel不对签证入境负责。</li>
                        <li>如果在边境遇到移民拒绝入境的情况下，乘客将不会有资格获得车票退款。</li>
                    </ul>
                    <li class="main_text">预订与座位选择</li>
                    <ul>
                        <li>乘客可以通过以下方式选择自己喜欢的座位并购买实时车票：</li>
                        <li>预订热线：+855 81 911 911</li>
                        <li>手机App：VET Express</li>
                        <li><a href="http://www.vireakbuntham.com/">网站：www.vireakbuntham.com</a></li>
                        <li>任何VET车站</li>
                    </ul>
                    <li class="main_text">行李政策</li>
                    <ul>
                        <li>乘客最多可带 25 公斤的行李和一件随身包。</li>
                        <li>超重行李将根据当地配送费用收取额外费用。</li>
                    </ul>
                    <li class="main_text">禁带物品</li>
                    <ul>
                        <li>以下物品严格禁止携带上车：</li>
                        <li>强烈气味的食物（如榴莲）</li>
                        <li>宠物及其他活体动物</li>
                        <li>各类枪支 </li>
                        <li>各种类型的毒品、吸烟、电子烟及药物滥用行为</li>
                        <li>走私或非法物品</li>
                        <li>危险、易燃或爆炸物品</li>
                    </ul>
                    <li class="main_text">乘客责任</li>
                    <ul>
                        <li>乘客对个人物品和贵重物品的安全负责</li>
                        <li>VET 对任何个人物品的丢失或损坏不承担责任。</li>
                    </ul>
                    <li class="main_text">乘客责任</li>
                    <ul>
                        <li>乘客对个人物品和贵重物品的安全负责</li>
                        <li>VET 对任何个人物品的丢失或损坏不承担责任。</li>
                    </ul>
                </ol>
            </div>
        </div>
    </div>
</template>

<script>
import VETimg from '@/assets/icon_bars/Picture1.jpg';

export default {
    name: 'TermsConditionTicket',
    data() {
        return {
            VETimg, // Expose the imported image for use in the template
        };
    },
};
</script>

<style>
.main_text {
    font-weight: 600;
    font-size: 14px;
    color: #1E1E1F;
}

.termscondition {
    /* padding: 15px; */
    text-align: justify;
}

.container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    gap: 20px;
    padding-bottom: 50px;
}

ul li {
    line-height: 26px;
}
</style>
